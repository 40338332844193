import axios from 'axios';

class PersonGroupApi {
    static list = () => {
        return axios.get(`${process.env.REACT_APP_API_URL}/personGroup?max=100`)
    }

    static get = (id) => {
        return axios.get(`${process.env.REACT_APP_API_URL}/personGroup/${id}`)
    }

    static create = (name) => {
        return axios.post(`${process.env.REACT_APP_API_URL}/personGroup`, {
            name
        })
    }
}

export default PersonGroupApi;