import {
    PERSON_GROUP_CREATE_SUCCESS, PERSON_GROUP_LIST, PERSON_GROUP_LIST_SUCCESS,
} from "../../config/actions";

export const personGroupList = () => {
    return {
        type: PERSON_GROUP_LIST
    }
};

export const personGroupListSuccess = (data) => {
    return {
        type: PERSON_GROUP_LIST_SUCCESS,
        data
    }
};

export const personGroupCreateSuccess = (data) => {
    return {
        type: PERSON_GROUP_CREATE_SUCCESS,
        data
    }
};