import React, {useEffect, useState} from 'react';
import SelectInput from "../../elements/formElements/SelectInput";
import {useDispatch, useSelector} from "react-redux";
import moment from 'moment';
import 'moment/locale/de';
import {setDateFilter} from "../../store/filter/filterAction";

function DateFilter() {
    const dispatch = useDispatch();
    const dateFilter = useSelector(state => state.filter.dateFilter);
    const attendances = useSelector(state => state.attendance.attendances);
    const [monthsToShow, setMonthsToShow] = useState(null);

    useEffect(() => {
        let minDate = moment.min(attendances.map(el => moment(el.startAt))).clone().startOf('month');
        let maxDate = moment.max(attendances.map(el => moment(el.endAt))).clone().endOf('month');
        let lMonthsToShow = [];
        let tempDate = minDate.clone();
        do {
            lMonthsToShow.push(tempDate);
            tempDate = tempDate.clone().add(1, 'month');
        } while (tempDate.isSameOrBefore(maxDate));

        setMonthsToShow(lMonthsToShow);
    }, [attendances])


    return monthsToShow ? (
        <div className="DateFilter">
            <SelectInput value={dateFilter ? moment(dateFilter).format('MM.YY') : ''} options={[
                {
                    value: '',
                    label: 'alle'
                },
                ...monthsToShow.map(m => ({
                    value: m.format('MM.YY'),
                    label: m.format('MMMM YY')
                }))
            ]} onChange={(val) => dispatch(setDateFilter(val !== '' ? moment(val, 'MM.YY') : null))}/>
        </div>
    ) : null
}

export default DateFilter;