import React, {useState} from 'react';
import TextInput from "../../elements/formElements/TextInput";
import Button from "../../elements/formElements/Button";
import AuthorizationApi from "../../api/AuthorizationApi";
import {useDispatch} from "react-redux";
import {authorizationLoginSuccess} from "../../store/authorization/authorizationAction";

function Login() {
    const dispatch = useDispatch();
    const [state, setState] = useState({
        loading: false,
        loginError: false
    });
    const [values, setValues] = useState({
        username: '',
        password: ''
    });

    const setValue = (key, value) => {
        setValues({...values, [key]: value});
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (values.username.length === 0 || values.password.length === 0) {
            setState({...state, loading: false, loginError: true});
            return;
        }
        setState({...state, loading: true, loginError: false});
        AuthorizationApi.login(values.username, values.password)
            .then(response => {
                setState({...state, loading: false});
                if (response.status === 200) {
                    let {access_token, username, roles} = response.data;
                    dispatch(authorizationLoginSuccess(access_token, username, roles));
                } else {
                    setState({...state, loginError: true});
                }
            })
            .catch(() => {
                setState({...state, loading: false, loginError: true});
            })
    };

    return (
        <div className="Login">
            <div className="row mt-5 justify-content-center">
                <div className="col-12 col-md-4 col-lg-3">
                    <h1 className="text-center">
                        {process.env.REACT_APP_BRAND_NAME}
                    </h1>
                    <div className="card mt-4">
                        <div className="card-header">
                            <h4 className="mb-0">
                                Login
                            </h4>
                        </div>
                        <div className="card-body">
                            <p>
                                Bitte melde dich an
                            </p>
                            <form onSubmit={handleFormSubmit}>
                                <TextInput label="Benutzername" value={values.username} autoFocus
                                           onChange={(value) => setValue('username', value)}/>
                                <TextInput label="Passwort" type="password"
                                           value={values.password} onChange={(value) => setValue('password', value)}/>
                                {state.loginError && (
                                    <p className="text-danger">
                                        Bitte überprüfe die eingegebenen Login-Daten.
                                    </p>
                                )}
                                <Button label="Login" loading={state.loading}/>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;