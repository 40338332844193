import '../theme/theme.scss';
import 'react-notifications/lib/notifications.css';
import React from "react";
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import {useSelector} from "react-redux";
import {Route, Router, Switch} from "react-router";
import Login from "./Login";
import AxiosHelper from "../config/axiosHelper";
import TopNavigation from "../elements/navigation/TopNavigation";
import {ROUTE_IMPORT, ROUTE_LOGIN, ROUTE_LOGOUT, ROUTE_OVERVIEW} from "../config/routes";
import Logout from "./Logout";
import Overview from "./Overview";
import DataLoader from "../elements/data/DataLoader";
import {NotificationContainer} from "react-notifications";
import Import from "./Import";

function App({history}) {
    const bearerToken = useSelector(state => state.authorization.bearerToken);
    AxiosHelper.init();
    return (
        <div className="App d-flex flex-column">
            {bearerToken ? (
                <Router history={history}>
                    <DataLoader/>
                    <div className="d-flex flex-column flex-grow-1">
                        <TopNavigation/>
                        <div className="container-fluid mt-3 flex-grow-1 d-flex">
                            <Switch>
                                <Route path={ROUTE_LOGOUT} exact component={Logout}/>
                                <Route path={ROUTE_IMPORT} exact component={Import}/>
                                <Route path={ROUTE_OVERVIEW} exact component={Overview}/>
                            </Switch>
                        </div>
                    </div>
                </Router>
            ) : (
                <div className="container-fluid">
                    <Router history={history}>
                        <Switch>
                            <Route path={ROUTE_LOGIN} component={Login}/>
                        </Switch>
                    </Router>
                </div>
            )}
            <NotificationContainer/>
        </div>
    );
}

export default App;
