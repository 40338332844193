import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import moment from "moment";
import {faCarrot, faDrumstickBite, faEgg, faUsers} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function PersonGroupAttendanceBar({bar, personGroup, persons, startOfCurrentMonth, endOfCurrentMonth, minDate}) {
    const attendances = useSelector(state => state.attendance.attendances);
    const dateFilter = useSelector(state => state.filter.dateFilter);
    const [durationDays, setDurationDays] = useState(null);
    const [offsetStart, setOffsetStart] = useState(null);
    const [lPersons, setLPersons] = useState(null);

    useEffect(() => {
        let startAt, endAt;
        if (dateFilter && startOfCurrentMonth && endOfCurrentMonth) {
            startAt = bar.startAt.isBefore(startOfCurrentMonth) ? startOfCurrentMonth : bar.startAt;
            endAt = bar.endAt.isAfter(endOfCurrentMonth) ? endOfCurrentMonth : bar.endAt;
        } else {
            startAt = bar.startAt;
            endAt = bar.endAt;
        }

        setDurationDays(Math.round(moment.duration(endAt.diff(startAt)).asDays()));
        setOffsetStart(Math.round(moment.duration(startAt.diff(minDate)).asDays()));

        let lAttendances = attendances.filter(el => persons.map(p => p.id).includes(el.person.id));
        lAttendances = lAttendances.filter(el => el.startAt.isSameOrAfter(startAt) && el.endAt.isSameOrBefore(endAt));
        setLPersons(persons.filter(p => lAttendances.map(a => a.person?.id).includes(p.id)));
    }, [attendances, dateFilter, persons, bar, startOfCurrentMonth, endOfCurrentMonth, minDate]);


    return durationDays && offsetStart && lPersons ? (
        <div className={`PersonGroupAttendanceBar`}
             style={{width: (durationDays + 1) * 45, left: offsetStart * 45}}>
            <div className="title ml-3">
                <span className="mr-4">
                    <FontAwesomeIcon icon={faUsers} className="mr-2"/>
                    {lPersons.length}
                </span>
                <span className="mr-2">
                    <FontAwesomeIcon icon={faDrumstickBite} className="mr-1"/>
                    {lPersons.filter(p => p.lunchType === 'MEAT').length}
                </span>
                <span className="mr-2">
                    <FontAwesomeIcon icon={faEgg} className="mr-1"/>
                    {lPersons.filter(p => p.lunchType === 'VEGETARIAN').length}
                </span>
                <span className="mr-2">
                    <FontAwesomeIcon icon={faCarrot} className="mr-1"/>
                    {lPersons.filter(p => p.lunchType === 'VEGAN').length}
                </span>
            </div>
        </div>
    ) : null
}

export default React.memo(PersonGroupAttendanceBar);