import createSagaMiddleware from 'redux-saga';
import persistState from 'redux-localstorage';
import rootSaga from './store/rootSaga';
import rootReducer from './store/rootReducer';
import {applyMiddleware, compose, createStore} from "redux";

const sagaMiddleware = createSagaMiddleware();

const enhancer = compose(
    applyMiddleware(sagaMiddleware),
    persistState('authorization', {key: process.env.REACT_APP_KEY})
);

const store = createStore(rootReducer, enhancer);
sagaMiddleware.run(rootSaga);
export {store};