import {
    PERSON_CREATE_SUCCESS,
    PERSON_DELETE_SUCCESS,
    PERSON_LIST,
    PERSON_LIST_SUCCESS,
    PERSON_UPDATE_SUCCESS
} from "../../config/actions";

export const personList = () => {
    return {
        type: PERSON_LIST
    }
};

export const personListSuccess = (data) => {
    return {
        type: PERSON_LIST_SUCCESS,
        data
    }
};

export const personCreateSuccess = (data) => {
    return {
        type: PERSON_CREATE_SUCCESS,
        data
    }
};

export const personUpdateSuccess = (data) => {
    return {
        type: PERSON_UPDATE_SUCCESS,
        data
    }
};

export const personDeleteSuccess = (id) => {
    return {
        type: PERSON_DELETE_SUCCESS,
        id
    }
};