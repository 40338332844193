import moment from 'moment';
import {
    ATTENDANCE_CREATE_SUCCESS,
    ATTENDANCE_DELETE_SUCCESS,
    ATTENDANCE_LIST_SUCCESS,
    ATTENDANCE_UPDATE_SUCCESS,
    PERSON_DELETE_SUCCESS
} from "../../config/actions";

let defaultState = {
    attendances: [],
    loaded: false
};

const attendanceReducer = (state = defaultState, action) => {
    switch (action.type) {
        case ATTENDANCE_LIST_SUCCESS:
            return {
                ...state,
                loaded: true,
                attendances: action.data?.map(attendance => {
                    return {
                        ...attendance,
                        startAt: moment(attendance.startAt).startOf('day'),
                        endAt: moment(attendance.endAt).startOf('day')
                    }
                })
            };
        case ATTENDANCE_CREATE_SUCCESS:
            return {
                ...defaultState,
                attendances: [...state.attendances, {
                    ...action.data,
                    startAt: moment(action.data.startAt).startOf('day'),
                    endAt: moment(action.data.endAt).startOf('day')
                }]
            };
        case ATTENDANCE_UPDATE_SUCCESS:
            return {
                ...defaultState,
                attendances: state.attendances.map(sP => sP.id === action.data.id ? {
                    ...action.data,
                    startAt: moment(action.data.startAt).startOf('day'),
                    endAt: moment(action.data.endAt).startOf('day')
                } : sP)
            }
        case ATTENDANCE_DELETE_SUCCESS:
            return {
                ...defaultState,
                attendances: state.attendances.filter(sP => sP.id !== action.id)
            }
        case PERSON_DELETE_SUCCESS:
            return {
                ...defaultState,
                attendances: state.attendances.filter(sP => sP.person.id !== action.id)
            }
        default:
            return state;
    }
}

export default attendanceReducer;