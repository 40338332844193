import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {TAG_LIST} from "../../config/actions";
import TagApi from "../../api/TagApi";
import {tagListSuccess} from "./tagAction";


const loadTagsFromApi = async () =>
    TagApi.list()
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return null;
        });

function* doLoadTags() {
    try {
        const result = yield call(loadTagsFromApi);
        if (result) {
            yield put(tagListSuccess(result));
        }
    } catch (e) {
        console.log('error', e);
    }
}

export function* loadTags() {
    yield takeEvery([TAG_LIST], doLoadTags);
}

export default function* rootSaga() {
    yield all([fork(loadTags)]);
}
