import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import moment from 'moment';
import {faDrumstickBite, faEgg, faCarrot} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function AttendanceDays({}) {
    const attendances = useSelector(state => state.attendance.attendances);
    const persons = useSelector(state => state.person.persons);
    const dateFilter = useSelector(state => state.filter.dateFilter);
    const [daysToShow, setDaysToShow] = useState(null);

    useEffect(() => {
        let minDate, maxDate;
        if (dateFilter) {
            minDate = moment(moment(dateFilter).format('MM.YY'), 'MM.YY').clone().startOf('month');
            maxDate = moment(moment(dateFilter).format('MM.YY'), 'MM.YY').clone().endOf('month');
        } else {
            minDate = moment.min(attendances.map(el => el.startAt)).clone().subtract(1, 'day');
            maxDate = moment.max(attendances.map(el => el.endAt)).clone().add(1, 'day');
            if (moment.duration(maxDate.diff(minDate)).asDays() < 60) maxDate = minDate.clone().add(60, 'day');
        }
        let l_daysToShow = [];
        let tempDate = minDate.clone().startOf('day');
        do {
            l_daysToShow.push(tempDate);
            tempDate = tempDate.clone().add(1, 'day');
        } while (tempDate.isSameOrBefore(maxDate));

        setDaysToShow(l_daysToShow);
    }, [attendances, dateFilter]);

    return daysToShow ? (
        <div className="AttendanceDays">
            {daysToShow.map((dts, dtsI) => (
                <div
                    className={`AttendanceDay text-center border-top border-bottom border-right${dts.isSame(moment(), 'day') ? ' today' : ''}`}
                    key={dtsI}>
                    <small>
                        {dts.format('ddd')}<br/>
                        {dts.format('DD.MM.')}
                    </small>
                    <div className="attendance-amount border-top">
                        {attendances.filter(at => at.startAt.isSameOrBefore(dts) && at.endAt.isSameOrAfter(dts)).length}
                    </div>
                    <div className="attendance-eating-amount">
                        <small>
                            <FontAwesomeIcon icon={faDrumstickBite} className="fa-xs color-meat m-0"/>
                            {'   '}
                            <FontAwesomeIcon icon={faEgg} className="fa-xs color-vegetarian m-0"/>
                            {'   '}
                            <FontAwesomeIcon icon={faCarrot} className="fa-xs color-vegan m-0"/>
                            <p className="m-0"/>
                            <span
                                className="color-meat">
                                    {attendances.filter(at => at.startAt.isSameOrBefore(dts) && at.endAt.isSameOrAfter(dts) && (persons.find(p => p.id === at.person.id).lunchType === 'MEAT' || !persons.find(p => p.id === at.person.id).lunchType)).length}</span>
                            {'   '}
                            <span
                                className="color-vegetarian">
                                    {attendances.filter(at => at.startAt.isSameOrBefore(dts) && at.endAt.isSameOrAfter(dts) && persons.find(p => p.id === at.person.id).lunchType === 'VEGETARIAN').length}</span>
                            {'   '}
                            <span
                                className="color-vegan">
                                    {attendances.filter(at => at.startAt.isSameOrBefore(dts) && at.endAt.isSameOrAfter(dts) && persons.find(p => p.id === at.person.id).lunchType === 'VEGAN').length}</span>
                        </small>
                    </div>
                </div>
            ))}
        </div>
    ) : null
}

export default React.memo(AttendanceDays);