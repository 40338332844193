import {
    TAG_CREATE_SUCCESS,
    TAG_DELETE_SUCCESS,
    TAG_LIST,
    TAG_LIST_SUCCESS,
    TAG_UPDATE_SUCCESS
} from "../../config/actions";

export const tagList = () => {
    return {
        type: TAG_LIST
    }
};

export const tagListSuccess = (data) => {
    return {
        type: TAG_LIST_SUCCESS,
        data
    }
};

export const tagCreateSuccess = (data) => {
    return {
        type: TAG_CREATE_SUCCESS,
        data
    }
};

export const tagUpdateSuccess = (data) => {
    return {
        type: TAG_UPDATE_SUCCESS,
        data
    }
};

export const tagDeleteSuccess = (id) => {
    return {
        type: TAG_DELETE_SUCCESS,
        id
    }
};