import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import CreatePerson from "./CreatePerson";
import AttendanceDays from "./AttendanceDays";
import moment from "moment";
import animateScrollTo from "animated-scroll-to";
import DateFilter from "./DateFilter";
import PersonGroupInfo from "./PersonGroupInfo";
import PersonGroupAttendance from "./PersonGroupAttendance";
import {faArrowAltCircleDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DebounceInput} from "react-debounce-input";

const filterPersons = (cpersonFilter, cpersons, cpersonGroups) => {
    let lFilteredPersons = (!cpersonFilter || cpersonFilter.length === 0) ? cpersons : cpersons.filter(p => p.name.toLowerCase().includes(cpersonFilter.toLowerCase()))
    let lFilteredPersonGroups = [...cpersonGroups.filter(pg => lFilteredPersons.find(fp => fp.personGroup?.id === pg.id || (!fp.personGroup?.id && !pg.id)))];
    return {lFilteredPersons, lFilteredPersonGroups};
}

function Overview() {
    const persons = useSelector(state => state.person.persons);
    const personGroups = useSelector(state => state.personGroup.personGroups);
    const attendances = useSelector(state => state.attendance.attendances);
    const dateFilter = useSelector(state => state.filter.dateFilter);

    const [alreadyScrolledToCurrent, setAlreadyScrolledToCurrent] = useState(false);
    const [personFilter, setPersonFilter] = useState('');
    const [minDate, setMinDate] = useState(null);
    const [filteredPersons, setFilteredPersons] = useState(null);
    const [filteredPersonGroups, setFilteredPersonGroups] = useState(null);

    const [startOfCurrentMonth, setStartOfCurrentMonth] = useState(null);
    const [endOfCurrentMonth, setEndOfCurrentMonth] = useState(null);

    useEffect(() => {
        let minDate, tStartOfCurrentMonth, tEndOfCurrentMonth;
        if (dateFilter) {
            tStartOfCurrentMonth = moment(moment(dateFilter).format('MM.YY'), 'MM.YY').clone().startOf('month');
            tEndOfCurrentMonth = moment(moment(dateFilter).format('MM.YY'), 'MM.YY').clone().endOf('month');
            minDate = tStartOfCurrentMonth;
        } else {
            minDate = moment.min(attendances.map(el => el.startAt)).clone().subtract(1, 'day');
        }
        setStartOfCurrentMonth(tStartOfCurrentMonth);
        setEndOfCurrentMonth(tEndOfCurrentMonth);
        setMinDate(minDate);
    }, [attendances, dateFilter]);

    useEffect(() => {
        let lFilteredPersons = (!personFilter || personFilter.length === 0) ? persons : persons.filter(p => p.name.toLowerCase().includes(personFilter.toLowerCase()))
        let lFilteredPersonGroups = [...personGroups.filter(pg => lFilteredPersons.find(fp => fp.personGroup?.id === pg.id)), {
            id: null,
            name: 'Sonstige'
        }];
        setFilteredPersons(lFilteredPersons);
        setFilteredPersonGroups(lFilteredPersonGroups);
    }, [personFilter, persons, personGroups]);

    useEffect(() => {
        if(!alreadyScrolledToCurrent && attendances.length > 0 && minDate) {
            setTimeout(scrollToCurrent, 500);
        }
    }, [attendances, alreadyScrolledToCurrent]);

    const c_setPersonFilter = (value) => {
        setPersonFilter(value);
        doFilterPersons(value);
    }

    const doFilterPersons = (value) => {
        const result = filterPersons(value, persons, [...personGroups, {
            id: null,
            name: 'Sonstige'
        }]);
        setFilteredPersons(result.lFilteredPersons);
        setFilteredPersonGroups(result.lFilteredPersonGroups);
    }

    const scrollToCurrent = () => {
        if (!alreadyScrolledToCurrent) {
            setAlreadyScrolledToCurrent(true);
            let element = document.getElementById('attendances');
            let offsetInDays = Math.round(moment.duration(minDate.diff(moment())).asDays());
            if (element) animateScrollTo([(offsetInDays + 5) * 45 * -1, null], {
                elementToScroll: element
            }).then()
        }
    };

    const scrollToDate = (date) => {
        let element = document.getElementById('attendances');
        let offsetInDays = Math.round(moment.duration(minDate.diff(date)).asDays());
        if (element) animateScrollTo([(offsetInDays + 5) * 45 * -1, null], {
            elementToScroll: element
        }).then()
    }

    /*const addScrollEventListener = () => {
        let element = document.getElementById('attendances');
        if (element) element.addEventListener('scroll', handleScrollEvent);
    }

    const removeScrollEventListener = () => {
        let element = document.getElementById('attendances');
        if (element) element.removeEventListener('scroll', handleScrollEvent);
    }

    const handleScrollEvent = () => {
        let element = document.getElementById('attendances');
        if (scrollHandlerTimeout) clearTimeout(scrollHandlerTimeout)
        if (element) {
            scrollHandlerTimeout = setTimeout(() => {
                console.log(element.scrollLeft);
            }, 150);
        }
    }*/

    return (
        <div className="Overview row flex-grow-1 d-flex flex-column">
            <div className="col-12 flex-grow-1 d-flex flex-column">
                <div className="card flex-grow-1 d-flex flex-column mb-3">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-6 col-md-3">
                                <CreatePerson/>
                            </div>
                            <div className="col-6 col-md-3 offset-md-6">
                                <DateFilter/>
                            </div>
                        </div>
                        {persons && persons.length > 0 && (
                            <div className="schedule-container d-flex mt-3 border-right">
                                <div className="names border-top" style={{minWidth: '200px'}}>
                                    <div className="name-filter border-bottom border-left p-2">
                                        <DebounceInput type="text" className="form-control" value={personFilter}
                                               onChange={(e) => c_setPersonFilter(e.target.value)}
                                               placeholder="Suche..." debounceTimeout={500}/>
                                        <button onClick={() => scrollToDate(moment())} className="btn btn-secondary" title="zum aktuellen Tag scrollen">
                                            <FontAwesomeIcon icon={faArrowAltCircleDown}/>
                                        </button>
                                    </div>
                                    {[...personGroups, {
                                        id: null,
                                        name: 'Sonstige'
                                    }]?.map((personGroup, pgI) => (
                                        <PersonGroupInfo key={pgI} personGroup={personGroup} isVisible={filteredPersonGroups?.find(fpg => fpg.id === personGroup.id || (fpg.id === null && personGroup.id === null))}
                                                         persons={filteredPersons?.filter(fp => fp.personGroup?.id === personGroup.id || (!fp.personGroup?.id && !personGroup.id))}
                                                         scrollToDate={scrollToDate}/>
                                    ))}
                                </div>
                                <div className="attendances flex-grow-1" id="attendances">
                                    <div className="attendances-inner">
                                        <AttendanceDays/>
                                        <div className="attendances-scroll">
                                            {filteredPersonGroups?.map((personGroup, pgI) => (
                                                <PersonGroupAttendance key={pgI} personGroup={personGroup} startOfCurrentMonth={startOfCurrentMonth} endOfCurrentMonth={endOfCurrentMonth} minDate={minDate}
                                                                       persons={filteredPersons?.filter(fp => fp.personGroup?.id === personGroup.id || (!fp.personGroup?.id && !personGroup.id))}/>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Overview;