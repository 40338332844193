import React, {Fragment, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import {Modal} from "react-bootstrap";
import Button from "../../elements/formElements/Button";
import TextInput from "../../elements/formElements/TextInput";
import PersonApi from "../../api/PersonApi";
import {personUpdateSuccess} from "../../store/person/personAction";
import {useDispatch, useSelector} from "react-redux";
import SelectInput from "../../elements/formElements/SelectInput";
import PersonGroupApi from "../../api/PersonGroupApi";
import {personGroupCreateSuccess} from "../../store/personGroup/personGroupAction";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";

function UpdatePerson({person}) {
    const dispatch = useDispatch();
    const personGroups = useSelector(state => state.personGroup.personGroups);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [personGroupsLoading, setPersonGroupsLoading] = useState(false);
    const [name, setName] = useState('');
    const [personGroup, setPersonGroup] = useState(null);
    const [lunchType, setLunchType] = useState('MEAT');
    const [lunchComment, setLunchComment] = useState('');
    const [externalReference, setExternalReference] = useState('');
    const [externalReference2, setExternalReference2] = useState('');

    const handleOpen = () => {
        let personGroupName = personGroups.find(pg => person.personGroup?.id === pg.id)?.name;
        setOpen(true);
        setName(person.name);
        setPersonGroup(personGroupName ? {label: personGroupName, value: personGroupName} : null);
        setLunchType(person.lunchType);
        setLunchComment(person.lunchComment);
        setExternalReference(person.externalReference);
        setExternalReference2(person.externalReference2);
    };

    const handleClose = () => {
        setOpen(false);
        setLoading(false);
        setName('');
        setPersonGroup(null);
        setLunchType('MEAT');
        setLunchComment('');
        setExternalReference('');
        setExternalReference2('');
    }

    const handleSubmitForm = (e) => {
        e.preventDefault();
        setLoading(true);
        PersonApi.update(person.id, name, personGroups.find(pg => pg.name === personGroup?.value)?.id, lunchType, lunchComment, externalReference, externalReference2, [])
            .then(response => {
                setLoading(false);
                if (response.status === 200) {
                    dispatch(personUpdateSuccess(response.data));
                    handleClose();
                }
            })
    }

    const handlePersonGroupChange = (newValue) => {
        setPersonGroup(newValue)
    }

    const handlePersonGroupCreate = (inputValue) => {
        setPersonGroupsLoading(true);
        PersonGroupApi.create(inputValue)
            .then(response => {
                if (response.status === 201) {
                    dispatch(personGroupCreateSuccess(response.data));
                    setPersonGroup({label: inputValue, value: inputValue});
                    setPersonGroupsLoading(false);
                }
            })
    }

    return (
        <Fragment>
            <button className="btn btn-link btn-sm" onClick={handleOpen}>
                <FontAwesomeIcon icon={faEdit}/>
            </button>
            <Modal show={open} onHide={handleClose} size="lg">
                <form onSubmit={handleSubmitForm}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Person bearbeiten
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-6">
                                <TextInput
                                    label="Name"
                                    onChange={(value) => setName(value)}
                                    value={name}/>
                            </div>
                            <div className="col-6">
                                <div className="CreatableSelectInput form-group">
                                    <label>Gruppe</label>
                                    <CreatableSelect isClearable isDisabled={personGroupsLoading}
                                                     isLoading={personGroupsLoading} onChange={handlePersonGroupChange}
                                                     formatCreateLabel={(inputValue) =>
                                                         <span>Erstelle '{inputValue}'</span>}
                                                     onCreateOption={handlePersonGroupCreate} value={personGroup}
                                                     placeholder="Auswählen oder erstellen..."
                                                     options={personGroups.map(pg => {
                                                         return {label: pg.name, value: pg.name}
                                                     }).sort((a, b) => (a.value.toLowerCase() > b.value.toLowerCase()) ? 1 : ((b.value.toLowerCase() > a.value.toLowerCase()) ? -1 : 0))}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <SelectInput value={lunchType} onChange={(val) => setLunchType(val)} label="Essen"
                                             options={[
                                                 {
                                                     value: 'MEAT',
                                                     label: 'Fleisch'
                                                 },
                                                 {
                                                     value: 'VEGETARIAN',
                                                     label: 'Vegetarisch'
                                                 },
                                                 {
                                                     value: 'VEGAN',
                                                     label: 'Vegan'
                                                 },
                                                 {
                                                     value: 'SELF_SUFFICIENT',
                                                     label: 'Selbstversorger:in'
                                                 }
                                             ]}/>
                            </div>
                            <div className="col-8">
                                <TextInput label="Unverträglichkeiten, Allergien, ..." value={lunchComment}
                                           onChange={(val) => setLunchComment(val)}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <TextInput label="Identifikationsnummer (Kärtchen)" value={externalReference}
                                           onChange={(val) => setExternalReference(val)}/>
                            </div>
                            <div className="col-6">
                                <TextInput label="Bändsel" value={externalReference2}
                                           onChange={(val) => setExternalReference2(val)}/>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="button" color="secondary" onClick={handleClose}
                                label="Abbrechen"/>
                        <Button color="success" label="Speichern" loading={loading}/>
                    </Modal.Footer>
                </form>
            </Modal>
        </Fragment>
    )
}

export default React.memo(UpdatePerson);