import {
    ATTENDANCE_CREATE_SUCCESS,
    ATTENDANCE_DELETE_SUCCESS,
    ATTENDANCE_LIST,
    ATTENDANCE_LIST_SUCCESS,
    ATTENDANCE_UPDATE_SUCCESS
} from "../../config/actions";

export const attendanceList = () => {
    return {
        type: ATTENDANCE_LIST
    }
};

export const attendanceListSuccess = (data) => {
    return {
        type: ATTENDANCE_LIST_SUCCESS,
        data
    }
};

export const attendanceCreateSuccess = (data) => {
    return {
        type: ATTENDANCE_CREATE_SUCCESS,
        data
    }
};

export const attendanceUpdateSuccess = (data) => {
    return {
        type: ATTENDANCE_UPDATE_SUCCESS,
        data
    }
};

export const attendanceDeleteSuccess = (id) => {
    return {
        type: ATTENDANCE_DELETE_SUCCESS,
        id
    }
};