import React from 'react';

function SelectInput({name, label, value, onChange, helpText, options}) {
    return (
        <div className="SelectInput form-group">
            {label && <label>{label}</label>}
            <select name={name} className="form-control" onChange={(e) => onChange ? onChange(e.target.value) : null} value={value}>
                {options && options.map((option, oI) => (
                    <option key={oI} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            {helpText && (
                <span className="help-block small d-block">{helpText}</span>
            )}
        </div>
    )
}

export default SelectInput;