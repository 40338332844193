import authorizationReducer from "./authorization/authorizationReducer";
import personReducer from "./person/personReducer";
import personGroupReducer from "./personGroup/personGroupReducer";
import tagReducer from "./tag/tagReducer";
import attendanceReducer from "./attendance/attendanceReducer";
import filterReducer from "./filter/filterReducer";
import {combineReducers} from "redux";

const rootReducer = combineReducers({
    authorization: authorizationReducer,
    person: personReducer,
    personGroup: personGroupReducer,
    tag: tagReducer,
    attendance: attendanceReducer,
    filter: filterReducer
});

const rootReducerWrapper = (state, action) => {
    return rootReducer(state, action);
}

export default rootReducerWrapper;