import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import Attendance from "./Attendance";
import moment from "moment";

function PersonAttendance({person, startOfCurrentMonth, endOfCurrentMonth, minDate}) {
    const attendances = useSelector(state => state.attendance.attendances);
    const dateFilter = useSelector(state => state.filter.dateFilter);
    const [lAttendances, setLAttendances] = useState([]);

    useEffect(() => {
        if (dateFilter) {
            setLAttendances(attendances.filter(at => moment(at.startAt).isSameOrBefore(endOfCurrentMonth) && moment(at.endAt).isSameOrAfter(startOfCurrentMonth)))
        } else {
            setLAttendances(attendances);
        }
    }, [attendances, dateFilter, startOfCurrentMonth, endOfCurrentMonth]);

    return (
        <div className="PersonAttendance d-flex flex-grow-1">
            {lAttendances.filter(el => el.person.id === person.id).map((attendance, aI) => (
                <Attendance key={aI} attendance={attendance} endOfCurrentMonth={endOfCurrentMonth} minDate={minDate}/>
            ))}
        </div>
    )
}

export default React.memo(PersonAttendance);