import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import moment from "moment";
import PersonGroupAttendanceBar from "./PersonGroupAttendanceBar";

function PersonGroupAttendanceBars({personGroup, persons, startOfCurrentMonth, endOfCurrentMonth, minDate}) {
    const attendances = useSelector(state => state.attendance.attendances);
    const dateFilter = useSelector(state => state.filter.dateFilter);
    const [lBars, setLBars] = useState(null);

    useEffect(() => {
        let lAttendances = attendances.filter(el => persons.map(p => p.id).includes(el.person.id));
        if (dateFilter) {
            lAttendances = lAttendances.filter(at => at.startAt.isSameOrBefore(endOfCurrentMonth) && at.endAt.isSameOrAfter(startOfCurrentMonth));
        }

        let bars = [];
        persons.forEach(person => {
            let personAttendances = lAttendances.filter(el => el.person.id === person.id);
            personAttendances.forEach(personAttendance => {
                let currentBar = bars.find(bar => bar.startAt.isSameOrBefore(personAttendance.startAt) && bar.endAt.isSameOrAfter(personAttendance.startAt));
                if (currentBar) {
                    if (currentBar.endAt.isBefore(personAttendance.endAt)) {
                        currentBar.endAt = personAttendance.endAt;
                    }
                } else {
                    currentBar = bars.find(bar => bar.startAt.isSameOrBefore(personAttendance.endAt) && bar.endAt.isSameOrAfter(personAttendance.endAt));
                    if (currentBar) {
                        if (currentBar.startAt.isAfter(personAttendance.startAt)) {
                            currentBar.startAt = personAttendance.startAt;
                        }
                    } else {
                        currentBar = bars.find(bar => bar.startAt.isSameOrAfter(personAttendance.endAt) && bar.endAt.isSameOrBefore(personAttendance.endAt));
                        if (currentBar) {
                            currentBar.startAt = personAttendance.startAt;
                            currentBar.endAt = personAttendance.endAt;
                        } else {
                            bars = [...bars, {startAt: personAttendance.startAt, endAt: personAttendance.endAt}];
                        }
                    }
                }
            })
        })

        setLBars(bars.filter(bar1 => !bars.find(bar2 => (bar1.startAt !== bar2.startAt || bar1.endAt !== bar2.endAt) && bar2.startAt.isSameOrBefore(bar1.startAt) && bar2.endAt.isSameOrAfter(bar1.endAt))));

    }, [attendances, persons, dateFilter, startOfCurrentMonth, endOfCurrentMonth, minDate])
    return lBars ? lBars.map((bar, bI) => <PersonGroupAttendanceBar bar={bar} personGroup={personGroup} persons={persons} key={bI} endOfCurrentMonth={endOfCurrentMonth} minDate={minDate}/>) : null;
}

export default PersonGroupAttendanceBars;