import React, {useEffect, useState} from 'react';
import PersonInfo from "./PersonInfo";
import {useDispatch, useSelector} from "react-redux";
import {togglePersonGroupVisibility} from "../../store/filter/filterAction";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function PersonGroupInfo({personGroup, persons, scrollToDate, isVisible}) {
    const dispatch = useDispatch();
    const visiblePersonGroupIds = useSelector(state => state.filter.visiblePersonGroupIds);
    const [groupIsVisible, setGroupIsVisible] = useState(false);

    useEffect(() => {
        setGroupIsVisible(visiblePersonGroupIds.includes(personGroup.id))
    }, [visiblePersonGroupIds, personGroup]);

    return (
        <div className={`PersonGroupInfo${!isVisible ? ' d-none' : ''}`}>
            <div className="PersonGroupInfoBlock d-flex align-items-center border-left border-right border-bottom pl-2 pr-2"
                 onClick={() => dispatch(togglePersonGroupVisibility(personGroup.id))}>
                <div className="flex-grow-1">
                    {personGroup.name}
                </div>
                <div>
                    <FontAwesomeIcon icon={faChevronDown} className="ml-3" rotation={groupIsVisible ? 180 : 0}/>
                </div>
            </div>
            <div className={groupIsVisible ? "d-block" : "d-none"}>
                {persons?.map((person, pI) => (
                    <PersonInfo key={pI} person={person} scrollToDate={scrollToDate}/>
                ))}
            </div>
        </div>
    )
}

export default React.memo(PersonGroupInfo);