import React, {Fragment, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {Modal} from "react-bootstrap";
import Button from "../../elements/formElements/Button";
import TextInput from "../../elements/formElements/TextInput";
import {useDispatch, useSelector} from "react-redux";
import AttendanceApi from "../../api/AttendanceApi";
import {attendanceCreateSuccess} from "../../store/attendance/attendanceAction";
import {DateRangePicker} from "react-dates";
import Checkbox from "../../elements/formElements/Checkbox";

function CreateAttendance({person, scrollToDate}) {
    const dispatch = useDispatch();
    const roles = useSelector(state => state.authorization.roles);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [startAt, setStartAt] = useState(null);
    const [endAt, setEndAt] = useState(null);
    const [comment, setComment] = useState('');
    const [sleepingPlace, setSleepingPlace] = useState('');
    const [coronaTestDay1, setCoronaTestDay1] = useState(false);
    const [coronaTestDay3, setCoronaTestDay3] = useState(false);
    const [coronaTestDay6, setCoronaTestDay6] = useState(false);
    const [focusedDatePicker, setFocusedDatePicker] = useState(null);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        if (!focusedDatePicker) {
            setOpen(false);
            setLoading(false);
            setStartAt(null);
            setEndAt(null);
            setComment('');
        }
    }

    const handleSubmitForm = (e) => {
        e.preventDefault();
        setLoading(true);
        AttendanceApi.create(startAt, endAt, comment, sleepingPlace, coronaTestDay1, coronaTestDay3, coronaTestDay6, person.id)
            .then(response => {
                setLoading(false);
                if (response.status === 201) {
                    scrollToDate(startAt);
                    dispatch(attendanceCreateSuccess(response.data));
                    handleClose();
                }
            })
    }

    return (
        <Fragment>
            <button className="btn btn-primary btn-sm btn-create-attendance" onClick={handleOpen}>
                <FontAwesomeIcon icon={faPlus}/>
            </button>
            <Modal show={open} onHide={handleClose}>
                <form onSubmit={handleSubmitForm}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Besuch von {person.name} hinzufügen
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label>
                                        Zeitraum
                                    </label>
                                    <div>
                                        <DateRangePicker
                                            startDate={startAt}
                                            endDate={endAt}
                                            minimumNights={0}
                                            onDatesChange={({startDate, endDate}) => {
                                                setStartAt(startDate);
                                                setEndAt(endDate)
                                            }}
                                            focusedInput={focusedDatePicker}
                                            onFocusChange={(val) => setTimeout(() => setFocusedDatePicker(val), 100)}
                                            startDatePlaceholderText="Von"
                                            endDatePlaceholderText="Bis"
                                            displayFormat="DD.MM.YY"
                                            isOutsideRange={() => false}
                                            startDateId={`startDateId-create-${person.id}`}
                                            endDateId={`endDateId-create-${person.id}`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <TextInput
                                    label="Schlafplatz" readOnly={!roles.includes('ROLE_MANAGER')}
                                    onChange={(value) => setSleepingPlace(value)}
                                    value={sleepingPlace}/>
                            </div>
                            <div className="col-6">
                                <TextInput
                                    label="Kommentar"
                                    onChange={(value) => setComment(value)}
                                    value={comment}/>
                            </div>
                        </div>
                        {/*<div className="row">
                            <div className="col-12">
                                <label>
                                    Corona-Tests
                                </label>
                            </div>
                            <div className="col-4">
                                <Checkbox name="createAttendanceCoronaTestDay1" value={coronaTestDay1}
                                          onChange={(val) => setCoronaTestDay1(val)} label="1. Tag"/>
                            </div>
                            <div className="col-4">
                                <Checkbox name="createAttendanceCoronaTestDay3" value={coronaTestDay3}
                                          onChange={(val) => setCoronaTestDay3(val)} label="3. Tag"/>
                            </div>
                            <div className="col-4">
                                <Checkbox name="createAttendanceCoronaTestDay6" value={coronaTestDay6}
                                          onChange={(val) => setCoronaTestDay6(val)} label="6. Tag"/>
                            </div>
                        </div>*/}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="button" color="secondary" onClick={handleClose}
                                label="Abbrechen"/>
                        <Button color="success" label="Speichern" loading={loading}/>
                    </Modal.Footer>
                </form>
            </Modal>
        </Fragment>
    )
}

export default React.memo(CreateAttendance);