import {all} from 'redux-saga/effects';
import attendanceSaga from "./attendance/attendanceSaga";
import personSaga from "./person/personSaga";
import tagSaga from "./tag/tagSaga";
import personGroupSaga from "./personGroup/personGroupSaga";

export default function* rootSaga(getState) {
    yield all([
        personSaga(),
        personGroupSaga(),
        tagSaga(),
        attendanceSaga()
    ])
}
