import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {PERSON_GROUP_LIST} from "../../config/actions";
import PersonGroupApi from "../../api/PersonGroupApi";
import {personGroupListSuccess} from "./personGroupAction";


const loadPersonGroupsFromApi = async () =>
    PersonGroupApi.list()
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return null;
        });

function* doLoadPersonGroups() {
    try {
        const result = yield call(loadPersonGroupsFromApi);
        if (result) {
            yield put(personGroupListSuccess(result));
        }
    } catch (e) {
        console.log('error', e);
    }
}

export function* loadPersonGroups() {
    yield takeEvery([PERSON_GROUP_LIST], doLoadPersonGroups);
}

export default function* rootSaga() {
    yield all([fork(loadPersonGroups)]);
}
