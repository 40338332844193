import axios from 'axios';

class AuthorizationApi {
    static login = (username, password) => {
        return axios.post(`${process.env.REACT_APP_API_URL}/login`, {
            username, password
        })
    }
}

export default AuthorizationApi;