import history from "../../history";
import {useDispatch} from "react-redux";
import {authorizationLogout} from "../../store/authorization/authorizationAction";

function Logout() {
    const dispatch = useDispatch();

    dispatch(authorizationLogout());
    history.push('/');

    return null;
}

export default Logout;