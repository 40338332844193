import React from 'react';

function TextInput({type = 'text', name, label, value, onChange, autoFocus, helpText, readOnly}) {
  return (
    <div className="TextInput form-group">
      <label>{label}</label>
      <input type={type} name={name} value={value} onChange={onChange ? (e) => onChange(e.target.value) : null} className="form-control" autoFocus={autoFocus} readOnly={readOnly}/>
      {helpText && (
        <span className="help-block small d-block">{helpText}</span>
      )}
    </div>
  )
}

export default TextInput;