import React, {useState} from 'react';
import * as XLSX from "xlsx";
import {useDispatch, useSelector} from "react-redux";
import PersonGroupApi from "../../api/PersonGroupApi";
import {personGroupCreateSuccess} from "../../store/personGroup/personGroupAction";
import PersonApi from "../../api/PersonApi";
import {personCreateSuccess} from "../../store/person/personAction";
import AttendanceApi from "../../api/AttendanceApi";
import moment from "moment";
import {attendanceCreateSuccess} from "../../store/attendance/attendanceAction";

function Import() {

    const dispatch = useDispatch();

    const personGroups = useSelector(state => state.personGroup.personGroups);

    const [dataToImport, setDataToImport] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleChangeFile = async (e) => {
        e.preventDefault();
        const reader = new FileReader();
        reader.onload = async (evt) => {
            try {
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, {type: 'binary'});
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws, {raw: false});
                setDataToImport(data);
            } catch (e) {
                alert('File could not be read.')
            }
        }
        reader.readAsBinaryString(e.target.files[0]);
    }

    const startImport = () => {
        setLoading(true);
        let createdPersonGroups = [];
        for (let i = 0, p = Promise.resolve(); i < dataToImport.length *2; i++) {
            p = p.then(_ => new Promise(resolve => {
                if (i < dataToImport.length) {
                    let groupId = personGroups.find(pg => pg.name === dataToImport[i].group);
                    if (!groupId) groupId = createdPersonGroups.find(pg => pg.name === dataToImport[i].group);
                    if (!groupId) {
                        PersonGroupApi.create(dataToImport[i].group)
                            .then(response => {
                                if (response.status === 201) {
                                    console.log(response.data);
                                    createdPersonGroups = [...createdPersonGroups, response.data];
                                    dispatch(personGroupCreateSuccess(response.data));
                                }
                                setTimeout(() => resolve(), 500);
                            })
                    } else {
                        resolve();
                    }
                } else {
                    let groupId = personGroups.find(pg => pg.name === dataToImport[i-dataToImport.length].group);
                    if (!groupId) groupId = createdPersonGroups.find(pg => pg.name === dataToImport[i-dataToImport.length].group);
                    PersonApi.create(dataToImport[i-dataToImport.length].name, groupId, 'MEAT', null, null, null, [])
                        .then(response => {
                            if (response.status === 201) {
                                dispatch(personCreateSuccess(response.data));

                                AttendanceApi.create(moment(dataToImport[i-dataToImport.length].startAt, 'DD.MM.YY'), moment(dataToImport[i-dataToImport.length].endAt, 'DD.MM.YY'), null, null, null, null, null, response.data.id)
                                    .then(response2 => {
                                        if (response2.status === 201) {
                                            dispatch(attendanceCreateSuccess(response2.data));
                                            dataToImport[i-dataToImport.length].status = 'Erfolgreich importiert';
                                        } else {
                                            dataToImport[i-dataToImport.length].status = `Error: ${JSON.stringify(response.data.errors)}`
                                        }
                                        if (!dataToImport.find(el => !el.status)) {
                                            setLoading(false);
                                        }
                                        resolve();
                                    })
                            }
                        })
                }
            }))
        }
    }

    return (
        <div className="Import">
            <h2>Import</h2>
            <input type="file" onChange={handleChangeFile}/>
            {dataToImport && dataToImport.length > 1 && (
                <div className="mt-5">
                    <table className="table table-bordered">
                        <tr>
                            <th>
                                Name
                            </th>
                            <th>
                                Gruppe
                            </th>
                            <th>
                                Anwesenheit
                            </th>
                            <th>
                                Status
                            </th>
                        </tr>
                        {dataToImport.map((data, dtI) => (
                            <tr key={dtI}>
                                <td>
                                    {data.name}
                                </td>
                                <td>
                                    {data.group}
                                </td>
                                <td>
                                    {data.startAt} - {data.endAt}
                                </td>
                                <td>
                                    {data.status ? data.status : ''}
                                </td>
                            </tr>
                        ))}
                    </table>
                    <button type="button" onClick={startImport} className="btn btn-primary mt-2 mb-5" disabled={loading}>
                        Import starten
                    </button>
                </div>
            )}
        </div>
    )
}

export default Import;