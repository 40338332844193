import axios from 'axios';

class PersonApi {
    static list = () => {
        return axios.get(`${process.env.REACT_APP_API_URL}/person`)
    }

    static get = (id) => {
        return axios.get(`${process.env.REACT_APP_API_URL}/person/${id}`)
    }

    static create = (name, personGroup, lunchType, lunchComment, externalReference, externalReference2, tags) => {
        return axios.post(`${process.env.REACT_APP_API_URL}/person`, {
            name,
            personGroup,
            lunchType,
            lunchComment,
            externalReference,
            externalReference2,
            tags
        })
    }

    static update = (id, name, personGroup, lunchType, lunchComment, externalReference, externalReference2, tags) => {
        return axios.put(`${process.env.REACT_APP_API_URL}/person/${id}`, {
            name,
            personGroup,
            lunchType,
            lunchComment,
            externalReference,
            externalReference2,
            tags
        })
    }

    static delete = (id) => {
        return axios.delete(`${process.env.REACT_APP_API_URL}/person/${id}`)
    }
}

export default PersonApi;