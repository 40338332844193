import axios from 'axios';

class AttendanceApi {
    static list = () => {
        return axios.get(`${process.env.REACT_APP_API_URL}/attendance`)
    }

    static get = (id) => {
        return axios.get(`${process.env.REACT_APP_API_URL}/attendance/${id}`)
    }

    static create = (startAt, endAt, comment, sleepingPlace, coronaTestDay1, coronaTestDay3, coronaTestDay6, person) => {
        return axios.post(`${process.env.REACT_APP_API_URL}/attendance`, {
            startAt,
            endAt,
            comment,
            sleepingPlace,
            coronaTestDay1,
            coronaTestDay3,
            coronaTestDay6,
            person
        })
    }

    static update = (id, startAt, endAt, comment, sleepingPlace, coronaTestDay1, coronaTestDay3, coronaTestDay5, coronaTestDay6) => {
        return axios.put(`${process.env.REACT_APP_API_URL}/attendance/${id}`, {
            startAt,
            endAt,
            comment,
            sleepingPlace,
            coronaTestDay1,
            coronaTestDay3,
            coronaTestDay5,
            coronaTestDay6
        })
    }

    static delete = (id) => {
        return axios.delete(`${process.env.REACT_APP_API_URL}/attendance/${id}`)
    }
}

export default AttendanceApi;