import React from 'react';
import {personList} from "../../store/person/personAction";
import {tagList} from "../../store/tag/tagAction";
import {attendanceList} from "../../store/attendance/attendanceAction";
import {connect} from "react-redux";
import {personGroupList} from "../../store/personGroup/personGroupAction";

class DataLoader extends React.Component {

    reloadDataInterval;

    componentDidMount() {
        this.loadData();
        this.reloadDataInterval = setInterval(this.loadData, 300000);
    }

    componentWillUnmount() {
        if(this.reloadDataInterval) clearInterval(this.reloadDataInterval);
    }

    loadData = () => {
        this.props.personList();
        this.props.tagList();
        this.props.attendanceList();
        this.props.personGroupList();
    };

    render() {
        return null;
    }
}

export default connect(null, {
    personList, tagList, attendanceList, personGroupList
})(DataLoader);