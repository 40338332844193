import {
    PERSON_GROUP_CREATE_SUCCESS,
    PERSON_GROUP_LIST_SUCCESS,
} from "../../config/actions";

let defaultState = {
    personGroups: [],
    loaded: false
};

const personGroupReducer = (state = defaultState, action) => {
    switch (action.type) {
        case PERSON_GROUP_LIST_SUCCESS:
            return {
                ...state,
                loaded: true,
                personGroups: action.data
            };
        case PERSON_GROUP_CREATE_SUCCESS:
            return {
                ...defaultState,
                personGroups: [...state.personGroups, action.data]
            };
        default:
            return state;
    }
}

export default personGroupReducer;