import React, {Fragment, useEffect, useState} from 'react';
import moment from 'moment';
import UpdateAttendance from "./UpdateAttendance";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBed, faCarrot, faComment, faDrumstickBite, faEgg, faVial} from "@fortawesome/free-solid-svg-icons";

function Attendance({attendance, startOfCurrentMonth, endOfCurrentMonth, minDate}) {
    const attendances = useSelector(state => state.attendance.attendances);
    const persons = useSelector(state => state.person.persons);
    const dateFilter = useSelector(state => state.filter.dateFilter);

    useEffect(() => {
        let startAt, endAt;
        if (dateFilter && startOfCurrentMonth && endOfCurrentMonth) {
            startAt = attendance.startAt.isBefore(startOfCurrentMonth) ? startOfCurrentMonth : attendance.startAt;
            endAt = attendance.endAt.isAfter(endOfCurrentMonth) ? endOfCurrentMonth : attendance.endAt;
        } else {
            startAt = attendance.startAt;
            endAt = attendance.endAt;
        }

        setDurationDays(Math.round(moment.duration(endAt.diff(startAt)).asDays()));
        setOffsetStart(Math.round(moment.duration(startAt.diff(minDate)).asDays()));
        setPerson(persons.find(el => el.id === attendance.person.id));
    }, [attendance, attendances, persons, dateFilter, startOfCurrentMonth, endOfCurrentMonth, minDate]);

    const [person, setPerson] = useState(null);
    const [durationDays, setDurationDays] = useState(null);
    const [offsetStart, setOffsetStart] = useState(null);
    const [open, setOpen] = useState(false);

    return person && durationDays !== null && offsetStart !== null ? (
        <Fragment>
            <div className={`Attendance`}
                 style={{width: (durationDays + 1) * 45, left: offsetStart * 45}} onClick={() => setOpen(true)}>
                <div className="title ml-3">
                    {person.lunchType === 'MEAT' && (
                        <FontAwesomeIcon icon={faDrumstickBite} className="mr-3"/>
                    )}
                    {person.lunchType === 'VEGETARIAN' && (
                        <FontAwesomeIcon icon={faEgg} className="mr-3"/>
                    )}
                    {person.lunchType === 'VEGAN' && (
                        <FontAwesomeIcon icon={faCarrot} className="mr-3"/>
                    )}
                    {attendance.sleepingPlace && (
                        <span className="mr-3">
                            <FontAwesomeIcon icon={faBed} className="mr-2"/>
                            {attendance.sleepingPlace}
                        </span>
                    )}
                    {attendance.comment && (
                        <span className="mr-3">
                            <FontAwesomeIcon icon={faComment} className="mr-2"/>
                            {attendance.comment}
                        </span>
                    )}
                    {attendance.coronaTestDay1 && (
                        <span className="mr-2">
                            <FontAwesomeIcon icon={faVial} className="mr-1"/>
                            1
                        </span>
                    )}
                    {attendance.coronaTestDay3 && (
                        <span className="mr-2">
                            <FontAwesomeIcon icon={faVial} className="mr-1"/>
                            3
                        </span>
                    )}
                    {attendance.coronaTestDay5 && (
                        <span className="mr-2">
                            <FontAwesomeIcon icon={faVial} className="mr-1"/>
                            5
                        </span>
                    )}
                    {attendance.coronaTestDay6 && (
                        <span className="mr-2">
                            <FontAwesomeIcon icon={faVial} className="mr-1"/>
                            6
                        </span>
                    )}
                </div>
            </div>
            <UpdateAttendance attendance={attendance} open={open} setOpen={setOpen}/>
        </Fragment>
    ) : null
}

export default React.memo(Attendance);