import React from 'react';
import {useSelector} from "react-redux";
import PersonAttendance from "./PersonAttendance";
import PersonGroupAttendanceBars from "./PersonGroupAttendanceBars";

function PersonGroupAttendance({personGroup, persons, endOfCurrentMonth, startOfCurrentMonth, minDate}) {
    const visiblePersonGroupIds = useSelector(state => state.filter.visiblePersonGroupIds);
    return (
        <div className="PersonGroupAttendance">
            <div className="PersonGroupAttendanceBlock">
                <PersonGroupAttendanceBars personGroup={personGroup} persons={persons} startOfCurrentMonth={startOfCurrentMonth} endOfCurrentMonth={endOfCurrentMonth} minDate={minDate}/>
            </div>
            <div className={`${visiblePersonGroupIds.includes(personGroup.id) ? 'd-block' : 'd-none'}`}>
                {persons.map((person, pI) => (
                    <PersonAttendance key={pI} person={person} startOfCurrentMonth={startOfCurrentMonth} endOfCurrentMonth={endOfCurrentMonth} minDate={minDate}/>
                ))}
            </div>
        </div>
    )
}

export default React.memo(PersonGroupAttendance);