import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {ATTENDANCE_LIST} from "../../config/actions";
import AttendanceApi from "../../api/AttendanceApi";
import {attendanceListSuccess} from "./attendanceAction";


const loadAttendancesFromApi = async () =>
    AttendanceApi.list()
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return null;
        });

function* doLoadAttendances() {
    try {
        const result = yield call(loadAttendancesFromApi);
        if (result) {
            yield put(attendanceListSuccess(result));
        }
    } catch (e) {
        console.log('error', e);
    }
}

export function* loadAttendances() {
    yield takeEvery([ATTENDANCE_LIST], doLoadAttendances);
}

export default function* rootSaga() {
    yield all([fork(loadAttendances)]);
}
