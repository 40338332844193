import {FILTER_SET_DATE_FILTER, FILTER_TOGGLE_PERSON_GROUP_VISIBILITY} from "../../config/actions";

export const setDateFilter = (date) => {
    return {
        type: FILTER_SET_DATE_FILTER,
        dateFilter: date
    }
};

export const togglePersonGroupVisibility = (personGroupId) => {
    return {
        type: FILTER_TOGGLE_PERSON_GROUP_VISIBILITY,
        personGroupId
    }
};