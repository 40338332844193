import {
    TAG_CREATE_SUCCESS, TAG_DELETE_SUCCESS,
    TAG_LIST_SUCCESS, TAG_UPDATE_SUCCESS
} from "../../config/actions";

let defaultState = {
    tags: [],
    loaded: false
};

const tagReducer = (state = defaultState, action) => {
    switch (action.type) {
        case TAG_LIST_SUCCESS:
            return {
                ...state,
                loaded: true,
                tags: action.data
            };
        case TAG_CREATE_SUCCESS:
            return {
                ...defaultState,
                tags: [...state.tags, action.data]
            };
        case TAG_UPDATE_SUCCESS:
            return {
                ...defaultState,
                tags: state.tags.map(sP => sP.id === action.data.id ? action.data : sP)
            }
        case TAG_DELETE_SUCCESS:
            return {
                ...defaultState,
                tags: state.tags.filter(sP => sP.id !== action.id)
            }
        default:
            return state;
    }
}

export default tagReducer;