import axios from 'axios';

class TagApi {
    static list = () => {
        return axios.get(`${process.env.REACT_APP_API_URL}/tag`)
    }

    static get = (id) => {
        return axios.get(`${process.env.REACT_APP_API_URL}/tag/${id}`)
    }

    static create = (text) => {
        return axios.post(`${process.env.REACT_APP_API_URL}/tag`, {
            text
        })
    }

    static update = (id, text) => {
        return axios.put(`${process.env.REACT_APP_API_URL}/tag/${id}`, {
            text
        })
    }

    static delete = (id) => {
        return axios.delete(`${process.env.REACT_APP_API_URL}/tag/${id}`)
    }
}

export default TagApi;