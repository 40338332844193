import {FILTER_SET_DATE_FILTER, FILTER_TOGGLE_PERSON_GROUP_VISIBILITY} from "../../config/actions";

let defaultState = {
    dateFilter: null,
    visiblePersonGroupIds: []
};

const filterReducer = (state = defaultState, action) => {
    switch (action.type) {
        case FILTER_SET_DATE_FILTER:
            return {
                ...state,
                dateFilter: action.dateFilter
            };
        case FILTER_TOGGLE_PERSON_GROUP_VISIBILITY:
            if (state.visiblePersonGroupIds.filter(vpgi => (vpgi === null && action.personGroupId === null) || vpgi === action.personGroupId)?.length > 0) {
                return {
                    ...state,
                    visiblePersonGroupIds: state.visiblePersonGroupIds.filter(vpgi => (vpgi !== null && action.personGroupId === null) || vpgi !== action.personGroupId)
                };
            } else {
                return {
                    ...state,
                    visiblePersonGroupIds: [...state.visiblePersonGroupIds, action.personGroupId]
                }
            }
        default:
            return state;
    }
}

export default filterReducer;