import React, {Fragment, useState} from 'react';
import {Modal} from "react-bootstrap";

function FoundError() {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Fragment>
            <li className="nav-item">
                <a className="nav-link" onClick={handleOpen} href="#">
                    Fehler gefunden?
                </a>
            </li>
            <Modal show={open} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Fehler gefunden?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Du hast Fehler gefunden oder Verbesserungsvorschläge?<br/><br/>
                    Schreibe eine E-Mail an<br/>
                    <a href="mailto:gitlab+software-soljanka-soljanka-app-49-issue-@kulturkosmos.de">gitlab+software-soljanka-soljanka-app-49-issue-@kulturkosmos.de</a>
                </Modal.Body>
            </Modal>
        </Fragment>
    )
}

export default FoundError;