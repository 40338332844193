import axios from 'axios';
import {store} from '../store';
import {authorizationLogout} from "../store/authorization/authorizationAction";
import history from "../history";
import {NotificationManager} from "react-notifications";

class AxiosHelper {
    static init() {
        axios.interceptors.request.use((config) => {
            let token = store.getState().authorization.bearerToken;

            if (token) {
                config.headers.common['Authorization'] = `Bearer ${token}`
            }
            return config;
        });

        axios.interceptors.response.use(null, function (error) {
            if (error.response && [401, 403].includes(error.response.status)) {
                store.dispatch(authorizationLogout());
                history.push('/');
            } else {
                NotificationManager.error('Leider ist ein Fehler auftreten. Bitte versuche es nochmals.', 'Fehler!', 3000);
            }

            return Promise.reject(error);
        });
    }
}

export default AxiosHelper;
