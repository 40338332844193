import React from 'react';
import {Link} from "react-router-dom";
import {ROUTE_LOGOUT, ROUTE_OVERVIEW} from "../../config/routes";
import FoundError from "./FoundError";

function TopNavigation() {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <Link to={ROUTE_OVERVIEW} className="navbar-brand">
                {process.env.REACT_APP_BRAND_NAME}
            </Link>
            <ul className="navbar-nav mr-auto"/>
            <ul className="navbar-nav">
                <FoundError/>
                <li className="nav-item">
                    <Link to={ROUTE_LOGOUT} className="nav-link">
                        Abmelden
                    </Link>
                </li>
            </ul>
        </nav>
    )
}

export default TopNavigation;