import {
    PERSON_CREATE_SUCCESS, PERSON_DELETE_SUCCESS, PERSON_LIST_SUCCESS, PERSON_UPDATE_SUCCESS
} from "../../config/actions";

let defaultState = {
    persons: [],
    loaded: false
};

const personReducer = (state = defaultState, action) => {
    switch (action.type) {
        case PERSON_LIST_SUCCESS:
            return {
                ...state,
                loaded: true,
                persons: action.data
            };
        case PERSON_CREATE_SUCCESS:
            return {
                ...defaultState,
                persons: [...state.persons, action.data]
            };
        case PERSON_UPDATE_SUCCESS:
            return {
                ...defaultState,
                persons: state.persons.map(sP => sP.id === action.data.id ? action.data : sP)
            }
        case PERSON_DELETE_SUCCESS:
            return {
                ...defaultState,
                persons: state.persons.filter(sP => sP.id !== action.id)
            }
        default:
            return state;
    }
}

export default personReducer;