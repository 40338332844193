import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import Button from "../../elements/formElements/Button";
import TextInput from "../../elements/formElements/TextInput";
import {useDispatch, useSelector} from "react-redux";
import AttendanceApi from "../../api/AttendanceApi";
import {attendanceUpdateSuccess} from "../../store/attendance/attendanceAction";
import moment from 'moment';
import {DateRangePicker} from "react-dates";
import DeleteAttendance from "./DeleteAttendance";
import Checkbox from "../../elements/formElements/Checkbox";

function UpdateAttendance({attendance, open, setOpen}) {
    const dispatch = useDispatch();
    const persons = useSelector(state => state.person.persons);
    const roles = useSelector(state => state.authorization.roles);
    const [loading, setLoading] = useState(false);
    const [startAt, setStartAt] = useState(moment(attendance.startAt));
    const [endAt, setEndAt] = useState(moment(attendance.endAt));
    const [comment, setComment] = useState(attendance.comment);
    const [person, setPerson] = useState(null);
    const [sleepingPlace, setSleepingPlace] = useState(attendance.sleepingPlace);
    const [coronaTestDay1, setCoronaTestDay1] = useState(attendance.coronaTestDay1);
    const [coronaTestDay3, setCoronaTestDay3] = useState(attendance.coronaTestDay3);
    const [coronaTestDay5, setCoronaTestDay5] = useState(attendance.coronaTestDay5);
    const [coronaTestDay6, setCoronaTestDay6] = useState(attendance.coronaTestDay6);
    const [focusedDatePicker, setFocusedDatePicker] = useState(null);

    useEffect(() => {
        setPerson(persons.find(el => el.id === attendance.person.id));
    }, [persons, attendance]);

    const handleClose = () => {
        if (!focusedDatePicker) {
            setOpen(false);
            setLoading(false);
        }
    }

    const handleSubmitForm = (e) => {
        e.preventDefault();
        setLoading(true);
        AttendanceApi.update(attendance.id, startAt, endAt, comment, sleepingPlace, coronaTestDay1, coronaTestDay3, coronaTestDay5, coronaTestDay6)
            .then(response => {
                setLoading(false);
                if (response.status === 200) {
                    dispatch(attendanceUpdateSuccess(response.data));
                    handleClose();
                }
            })
    }

    return person ? (
        <Modal show={open} onHide={handleClose}>
            <form onSubmit={handleSubmitForm}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Besuch von {person.name} bearbeiten
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label>
                                    Zeitraum
                                </label>
                                <div>
                                    <DateRangePicker
                                        startDate={startAt}
                                        endDate={endAt}
                                        onDatesChange={({startDate, endDate}) => {
                                            setStartAt(startDate);
                                            setEndAt(endDate)
                                        }}
                                        focusedInput={focusedDatePicker}
                                        onFocusChange={(val) => setTimeout(() => setFocusedDatePicker(val), 100)}
                                        startDatePlaceholderText="Von"
                                        endDatePlaceholderText="Bis"
                                        displayFormat="DD.MM.YY"
                                        isOutsideRange={() => false}
                                        startDateId={`startDateId-update-${person.id}`}
                                        endDateId={`endDateId-update-${person.id}`}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <TextInput
                                label="Schlafplatz" readOnly={!roles.includes('ROLE_MANAGER')}
                                onChange={(value) => setSleepingPlace(value)}
                                value={sleepingPlace}/>
                        </div>
                        <div className="col-6">
                            <TextInput
                                label="Kommentar"
                                onChange={(value) => setComment(value)}
                                value={comment}/>
                        </div>
                    </div>
                    {/*<div className="row">
                        <div className="col-12">
                            <label>
                                Corona-Tests
                            </label>
                        </div>
                        <div className="col-4">
                            <Checkbox name="createAttendanceCoronaTestDay1" value={coronaTestDay1}
                                      onChange={(val) => setCoronaTestDay1(val)} label="1. Tag"/>
                        </div>
                        <div className="col-4">
                            <Checkbox name="createAttendanceCoronaTestDay3" value={coronaTestDay3}
                                      onChange={(val) => setCoronaTestDay3(val)} label="3. Tag"/>
                        </div>
                        {attendance.coronaTestDay5 && (
                            <div className="col-4">
                                <Checkbox name="createAttendanceCoronaTestDay5" value={coronaTestDay5}
                                          onChange={(val) => setCoronaTestDay5(val)} label="5. Tag"/>
                            </div>
                        )}
                        <div className="col-4">
                            <Checkbox name="createAttendanceCoronaTestDay6" value={coronaTestDay6}
                                      onChange={(val) => setCoronaTestDay6(val)} label="6. Tag"/>
                        </div>
                    </div>*/}
                </Modal.Body>
                <Modal.Footer>
                    <DeleteAttendance attendance={attendance} onSuccess={handleClose}/>
                    <Button type="button" color="secondary" onClick={handleClose}
                            label="Abbrechen"/>
                    <Button color="success" label="Speichern" loading={loading}/>
                </Modal.Footer>
            </form>
        </Modal>
    ) : null
}

export default React.memo(UpdateAttendance);