import React from 'react';
import UpdatePerson from "./UpdatePerson";
import DeletePerson from "./DeletePerson";
import CreateAttendance from "./CreateAttendance";

function PersonInfo({person, scrollToDate}) {
    return person ? (
        <div className="PersonInfo d-flex align-items-center border-left border-right border-bottom">
            <CreateAttendance person={person} scrollToDate={scrollToDate}/>
            <div className="name pl-3 mr-3 flex-grow-1">
                {person.name}
            </div>
            <div className="btn-group btn-group-sm mr-1">
                <UpdatePerson person={person}/>
                <DeletePerson person={person}/>
            </div>
        </div>
    ) : null
}

export default React.memo(PersonInfo);