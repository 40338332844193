// Authorization
export const AUTHORIZATION_LOGIN_SUCCESS = 'AUTHORIZATION_LOGIN_SUCCESS';
export const AUTHORIZATION_LOGOUT = 'AUTHORIZATION_LOGOUT';

export const PERSON_LIST = 'PERSON_LIST';
export const PERSON_LIST_SUCCESS = 'PERSON_LIST_SUCCESS';
export const PERSON_CREATE_SUCCESS = 'PERSON_CREATE_SUCCESS';
export const PERSON_UPDATE_SUCCESS = 'PERSON_UPDATE_SUCCESS';
export const PERSON_DELETE_SUCCESS = 'PERSON_DELETE_SUCCESS';

export const PERSON_GROUP_LIST = 'PERSON_GROUP_LIST';
export const PERSON_GROUP_LIST_SUCCESS = 'PERSON_GROUP_LIST_SUCCESS';
export const PERSON_GROUP_CREATE_SUCCESS = 'PERSON_GROUP_CREATE_SUCCESS';

export const TAG_LIST = 'TAG_LIST';
export const TAG_LIST_SUCCESS = 'TAG_LIST_SUCCESS';
export const TAG_CREATE_SUCCESS = 'TAG_CREATE_SUCCESS';
export const TAG_UPDATE_SUCCESS = 'TAG_UPDATE_SUCCESS';
export const TAG_DELETE_SUCCESS = 'TAG_DELETE_SUCCESS';

export const ATTENDANCE_LIST = 'ATTENDANCE_LIST';
export const ATTENDANCE_LIST_SUCCESS = 'ATTENDANCE_LIST_SUCCESS';
export const ATTENDANCE_CREATE_SUCCESS = 'ATTENDANCE_CREATE_SUCCESS';
export const ATTENDANCE_UPDATE_SUCCESS = 'ATTENDANCE_UPDATE_SUCCESS';
export const ATTENDANCE_DELETE_SUCCESS = 'ATTENDANCE_DELETE_SUCCESS';

export const FILTER_SET_DATE_FILTER = 'FILTER_SET_DATE_FILTER';
export const FILTER_TOGGLE_PERSON_GROUP_VISIBILITY = 'FILTER_TOGGLE_PERSON_GROUP_VISIBILITY';