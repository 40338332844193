import React, {Fragment, useState} from 'react';
import {Modal} from "react-bootstrap";
import Button from "../../elements/formElements/Button";
import {useDispatch, useSelector} from "react-redux";
import AttendanceApi from "../../api/AttendanceApi";
import {attendanceDeleteSuccess} from "../../store/attendance/attendanceAction";
import moment from 'moment';

function DeleteAttendance({attendance, onSuccess}) {
    const dispatch = useDispatch();
    const persons = useSelector(state => state.person.persons);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    let person = persons.find(el => el.id === attendance.person.id);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setLoading(false);
    }

    const handleSubmitForm = (e) => {
        e.preventDefault();
        setLoading(true);
        AttendanceApi.delete(attendance.id)
            .then(response => {
                setLoading(false);
                if (response.status === 204) {
                    if(onSuccess) onSuccess();
                    dispatch(attendanceDeleteSuccess(attendance.id));
                    handleClose();
                }
            })
    }

    return (
        <Fragment>
            <Button type="button" color="danger" onClick={handleOpen}
                    label="Besuch löschen"/>
            <Modal show={open} onHide={handleClose}>
                <form onSubmit={handleSubmitForm}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Besuch von {person.name} löschen
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Möchtest du den
                        &nbsp;Besuch <strong>{attendance.comment}</strong>
                        &nbsp;von <strong>{person.name}</strong>
                        &nbsp;vom <strong>{moment(attendance.startAt).format('DD.MM.YYY')}</strong>
                        &nbsp;bis <strong>{moment(attendance.endAt).format('DD.MM.YYYY')}</strong>
                        &nbsp;wirklich löschen?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="button" color="secondary" onClick={handleClose}
                                label="Abbrechen"/>
                        <Button type="button" color="danger" label="Löschen" loading={loading} onClick={handleSubmitForm}/>
                    </Modal.Footer>
                </form>
            </Modal>
        </Fragment>
    )
}

export default React.memo(DeleteAttendance);