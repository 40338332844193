import React, {Fragment, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {Modal} from "react-bootstrap";
import Button from "../../elements/formElements/Button";
import PersonApi from "../../api/PersonApi";
import {personDeleteSuccess} from "../../store/person/personAction";
import {useDispatch} from "react-redux";

function DeletePerson({person}) {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setLoading(false);
    }

    const handleSubmitForm = (e) => {
        e.preventDefault();
        setLoading(true);
        PersonApi.delete(person.id)
            .then(response => {
                setLoading(false);
                if(response.status === 204) {
                    dispatch(personDeleteSuccess(person.id));
                    handleClose();
                }
            })
    }

    return (
        <Fragment>
            <button className="btn btn-link btn-sm" onClick={handleOpen}>
                <FontAwesomeIcon icon={faTrash}/>
            </button>
            <Modal show={open} onHide={handleClose}>
                <form onSubmit={handleSubmitForm}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Person löschen
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Möchtest du <strong>{person.name}</strong> wirklich löschen?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="button" color="secondary" onClick={handleClose}
                                label="Abbrechen"/>
                        <Button color="danger" label="Löschen" loading={loading}/>
                    </Modal.Footer>
                </form>
            </Modal>
        </Fragment>
    )
}

export default React.memo(DeletePerson);